.App {
  text-align: center;
}

.App-header {
  background-color: #181818;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.question {
  letter-spacing: 3px;
}

.code {
  letter-spacing: 2px;
}

@keyframes gradient { 
  0%{background-position:0 0}
  100%{background-position:100% 0}
}

input { border-style: none; background: transparent; outline: none; }
button { padding: 0; background: none; border: none; outline: none; }

.webflow-style-input {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  border-radius: 2px;
  padding: 1.4rem 2rem 1.6rem;
  background: rgba(57, 63, 84, 0.8);
  box-sizing: border-box;
}

.form {
  position: relative;
    border: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 400px;
}

.webflow-style-input:after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 999;
  height: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  background-position: 0% 0%;
  background: linear-gradient(to right, #B294FF, #57E6E6, #FEFFB8, #57E6E6, #B294FF, #57E6E6);
  background-size: 500% auto;
  animation: gradient 3s linear infinite;
}

.webflow-style-input.wrong:after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 999;
  height: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  background-position: 0% 0%;
  background: red;
  background-size: 500% auto;
  animation: gradient 3s linear infinite;
}

.webflow-style-input.correct{
  background: #4DA167;
  color: white;
}

.webflow-style-input.correct:after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 999;
  height: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  background-position: 0% 0%;
  background: #4DA167;
  background-size: 500% auto;
  animation: gradient 3s linear infinite;
}

.webflow-style-input input {
  flex-grow: 1;
  color: #BFD2FF;
  font-size: 1.8rem;
  line-height: 2.4rem;
  vertical-align: middle;
  width: 100%;
  text-transform: uppercase;
}

.webflow-style-input input::-webkit-input-placeholder {
  color: #7881A1;
}

.webflow-style-input button {
  color:  #7881A1;
  font-size: 2.4rem;
  line-height: 2.4rem;
  vertical-align: middle;
  transition: color .25s;
}

.webflow-style-input button:hover {
  color: #BFD2FF;
}